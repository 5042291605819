//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import FirebaseService from '@/services/firebase.service';
export default {
  name: 'Login',
  data: function data() {
    return {
      loading: false,
      email: '',
      password: '',
      step: 'email',
      forceRenderKey: 0
    };
  },
  methods: {
    submitEmail: function submitEmail() {
      this.step = 'password';
      this.forceRenderKey += 1;
    },
    submit: function submit() {
      var _this = this;

      var that = this;
      this.loading = true;
      FirebaseService.signIn(this.email, this.password).then(function (user) {
        if (user.emailVerified) {
          that.loading = false;
          that.$router.push({
            name: 'lab-series'
          });
        } else {
          _this.$swal({
            title: _this.$t('##SweetAlertDescription Firebase Error auth/not-authenticated Title'),
            html: _this.$t('#SweetAlertDescription Firebase Error auth/not-authenticated Message'),
            showConfirmButton: false,
            showCancelButton: false,
            timer: 1500,
            icon: 'error'
          });
        }
      }).catch(function (error) {
        _this.$swal({
          title: _this.$t('#SweetAlertTitle Firebase Error'),
          html: _this.$t("#SweetAlertDescription Firebase Error ".concat(error.code)),
          showConfirmButton: false,
          showCancelButton: false,
          timer: 1500,
          icon: 'error'
        });

        that.loading = false;
      });
    },
    backToEmail: function backToEmail() {
      this.password = '';
      this.email = '';
      this.step = 'email';
      this.forceRenderKey += 1;
    }
  }
};
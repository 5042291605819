/* unplugin-vue-components disabled */import __unplugin_components_7 from '/workspace/src/components/ButtonCustom.vue';
import __unplugin_components_6 from '/workspace/src/components/FeatherIcon.vue';
import __unplugin_components_5 from '/workspace/src/components/FormInput.vue';
import __unplugin_components_4 from '/workspace/src/components/TelosLogo.vue';
import __unplugin_components_3 from '/workspace/src/components/ButtonCustom.vue';
import __unplugin_components_2 from '/workspace/src/components/FeatherIcon.vue';
import __unplugin_components_1 from '/workspace/src/components/FormInput.vue';
import __unplugin_components_0 from '/workspace/src/components/TelosLogo.vue';
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.forceRenderKey,attrs:{"id":"PreAuthPage"}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.step === 'email')?[_c('validation-observer',{ref:"emailForm",staticClass:"auth-page-form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"auth-page-scroll",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitEmail)}}},[_c('div',{staticClass:"auth-form-wrapper"},[_c('div',{staticClass:"auth-form-header"},[_c(__unplugin_components_0),_c('h1',{staticClass:"login-title"},[_vm._v(" "+_vm._s(_vm.$t('#Auth #Login Title'))+" ")])],1),_c('div',{staticClass:"auth-form-body"},[_c(__unplugin_components_1,{attrs:{"type":"email","name":_vm.$t('#Auth #Login #FormInputTitle Email'),"title":_vm.$t('#Auth #Login #FormInputTitle Email'),"placeholder":_vm.$t('#Auth #Login #FormInputPlaceholder Email'),"rules":"required|email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]),_c('div',{staticClass:"auth-form-actions"},[_c('div',{staticClass:"auth-links"},[_c('a',{staticClass:"link",attrs:{"href":"https://aluno.telosconecta.com/auth/sign-up","target":"new"}},[_c('span',[_vm._v(_vm._s(_vm.$t("#Auth #Login Create an account"))+" ")]),_c(__unplugin_components_2,{attrs:{"icon":"LogInIcon","size":"20"}})],1)]),_c(__unplugin_components_3,{attrs:{"type":"submit","variant":"primary","disabled":invalid,"title":_vm.$t('#Button Next'),"append-icon":"ArrowRightIcon"}})],1)])]}}],null,false,3970479825)})]:_vm._e(),(_vm.step === 'password')?[_c('validation-observer',{ref:"passwordForm",staticClass:"auth-page-form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('form',{staticClass:"auth-page-scroll",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"auth-form-wrapper"},[_c('div',{staticClass:"auth-form-header"},[_c(__unplugin_components_4),_c('h1',{staticClass:"login-title"},[_vm._v(" "+_vm._s(_vm.$t('#Auth #Login Title'))+" ")])],1),_c('div',{staticClass:"auth-form-body"},[_c(__unplugin_components_5,{attrs:{"type":"password","name":_vm.$t('#Auth #Login #FormInputTitle Password'),"title":_vm.$t('#Auth #Login #FormInputTitle Password'),"placeholder":_vm.$t('#Auth #Login #FormInputPlaceholder Password'),"rules":"required"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]),_c('div',{staticClass:"auth-form-actions"},[_c('div',{staticClass:"auth-links"},[_c('a',{staticClass:"link",on:{"click":_vm.backToEmail}},[_c('span',[_vm._v(_vm._s(_vm.$t("#Auth #Login Another Email"))+" ")]),_c(__unplugin_components_6,{attrs:{"icon":"AtSignIcon","size":"20"}})],1)]),_c(__unplugin_components_7,{attrs:{"type":"submit","variant":"primary","disabled":invalid,"title":_vm.$t('#Button Login'),"loading":_vm.loading}})],1)])]}}],null,false,1526833043)})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }